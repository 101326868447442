import { MenuListData, ShopTblData } from "./model";
import apps from "./apps";

let imageHost = null;

export const menuUri = (shop: ShopTblData, menu: MenuListData) => menuUriByShopid(shop.shopid, menu);

export const menuUriByShopid = (shopid: string, menu: MenuListData) => {
  if (imageHost == null) {
    imageHost = apps.profile == "prod" ?
        "https://pikashop-prod.s3.ap-southeast-2.amazonaws.com" :
        "https://pikashop-devel.s3.ap-southeast-2.amazonaws.com";
  }

  if (menu.imageVer > 0) {
    return { uri: `${imageHost}/menu/${shopid}/menu${menu.menuid}_${menu.imageVer}.jpg` };
  } else {
    return { uri: `${imageHost}/menu/no_image.jpg` };
  }
};

/**
 * 소숫점 1자리에서 반올림
 */
export const fixPrice = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}
