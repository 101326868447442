import {MenuListData, OptionListData, ShopTblData, UserData} from "./model";

class Apps {
    ipLast: string = "106"; // __DEV__일때 ip 주소 마지막 자리. 192.168.x.106. 초기 url로 변경 가능

    shopId: string;   // shopId
    tableNo: string;  // "ONLINE"이면 온라인 주문
    qrType: string;   // QR Type 3자리
    txId: number;   // tabletious tablet 연동 주문인 경우 tx.id
    profile: string;  // dev, prod, local, local200
    signature: string;  // QR validation 인증용 서명

    user: UserData;
    name: string;   // for takeaway order
    mobile: string; // for takeaway order

    currentShop: ShopTblData | null = null;

    selectedMenu: MenuListData | null = null;
    options: OptionListData[] | null = null;

    saleMode: SaleMode;

    ordNo: number;  // Takeaway인 경우 주문완료 후에 ordNo를 받아서 저장해둔다. checkout -> ordered 넘어갈 때 파라미터로 넘기는 걸로 리팩토링하자

    /** takeaway인지 table order인지 확인. Takeaway일 때는 name, mobile을 받는다 **/
    isTakeaway(): boolean {
        return this.currentShop.qrType.startsWith("TA");
    }

    toOptions(optionIds: number[]): OptionListData[] {
        if (this.options == null || optionIds == null) {
            return [];
        } else {
            return optionIds.map(optionId => this.options.find(option => option.optionId == optionId))
        }
    }

    isProd() {
        return this.profile == "prod";
    }

    /**
     * SaleMode 기준으로 현재 적용할 가격
     * 세일금액은 priceBase보다 작아야 한다
     * HappyHour이지만 해당 가격이 존재하지 않으면 NORMAL 모드 가격으로
     *
     * HappyHour1,2 Only인 경우엔 항상 SALE로 표시되고, 해당 SALE 금액이 표시된다.
     */
    getPrice(saleMode: SaleMode, m: MenuListData): number {
        if (m.happy1Only) return m.priceHappy1;
        if (m.happy2Only) return m.priceHappy2;

        switch(saleMode) {
            case SaleMode.NORMAL: return this.isSale(saleMode, m) ? m.priceSp : m.price;
            case SaleMode.HAPPY1: return this.isSale(saleMode, m) ? m.priceHappy1 : this.getPrice(SaleMode.NORMAL, m);
            case SaleMode.HAPPY2: return this.isSale(saleMode, m) ? m.priceHappy2 : this.getPrice(SaleMode.NORMAL, m);
            default: throw Error("Invalid SaleMode: " + saleMode);
        }
    }

    /**
     * sale icon을 붙여야하는 special 또는 happyhour 대상인지 확인
     * 세일 가격 적용됐는가. 모드와 가격 차이를 동시에 비교
     * HappyHour1,2 Only인 경우엔 항상 SALE로 표시한다. 단, 주문은 해당 시간에만 가능
     */
    isSale(saleMode: SaleMode, m: MenuListData): boolean {
        if (m.happy1Only || m.happy2Only) return true;

        switch (saleMode) {
            case SaleMode.NORMAL:
                return m.special && m.priceSp < m.price;
            case SaleMode.HAPPY1:
                return m.priceHappy1 != null && m.priceHappy1 < m.price;
            case SaleMode.HAPPY2:
                return m.priceHappy2 != null && m.priceHappy2 < m.price;
            default:
                throw Error("Invalid SaleMode: " + saleMode)
        }
    }

    calcTime(now: Date, stTime: Date): Date {
        const time = new Date(now.getTime());
        time.setHours(stTime[0]);
        time.setMinutes(stTime[1]);
        time.setSeconds(0);
        time.setMilliseconds(0);

        return time;
    }

    isBettwen(now: Date, stTime: Date, endTime: Date): boolean {
        const st = this.calcTime(now, stTime);
        const end = this.calcTime(now, endTime);

        // console.log(`current: ${now}`);
        // console.log(`happyXSt: ${st}`);
        // console.log(`happyXEnd: ${end}`);
        // console.log(`time: ${st.toLocaleTimeString().substring(0, 5)}`);
        //
        // console.log(`${st <= now} ${now <= end}`);

        return st <= now && now <= end;
    }

    /**
     * 현재 happyhour인지 확인
     * null || Happyhour
     */
    getHappyhour(): Happyhour | null {
        const now = new Date();
        const shop = this.currentShop;

        if (shop.happy1 && this.isBettwen(now, shop.happy1St, shop.happy1End))
            return new Happyhour(SaleMode.HAPPY1, this.calcTime(now, shop.happy1St), this.calcTime(now, shop.happy1End));
        else if (shop.happy2 && this.isBettwen(now, shop.happy1St, shop.happy1End))
            return new Happyhour(SaleMode.HAPPY2, this.calcTime(now, shop.happy2St), this.calcTime(now, shop.happy2End));
        else
            return new Happyhour(SaleMode.NORMAL, null, null);
    }

    getHappyhourDescr(happyHourMode: SaleMode): string {
        return (happyHourMode == SaleMode.HAPPY1) ? this.currentShop.happy1Descr : this.currentShop.happy2Descr;
    }

    getHappyhourTime(happyhour: Happyhour): string {
        return this.getTimeDescr(happyhour.start, happyhour.end);
    }

    getTimeDescr(start: Date, end: Date): string {
        let options: Intl.DateTimeFormatOptions = {
            hour: "2-digit", minute: "2-digit"
        };

        return "(" + start.toLocaleTimeString("en-AU", options).substring(0, 8) + " - " +
            end.toLocaleTimeString("en-AU", options).substring(0, 8) + ")";
    }

    getHappy1Time(): string {
        const now = new Date();
        return this.getTimeDescr(this.calcTime(now, this.currentShop.happy1St), this.calcTime(now, this.currentShop.happy1End));
    }

    getHappy2Time(): string {
        const now = new Date();
        return this.getTimeDescr(this.calcTime(now, this.currentShop.happy2St), this.calcTime(now, this.currentShop.happy2End));
    }

    /** 일부 매장은 상호 변경으로 시스템 shopid를 다른 이름으로 받아서 변환해서 사용하게 할 필요가 있다. */
    replaceShopId() {
        if (this.shopId == "koisushi") this.shopId = "onarollch";
    }

    // ordNo를 반환하고 0으로 초기화한다
    getOrdNoAndClear() {
        const ordNo = this.ordNo;
        this.ordNo = 0;
        return ordNo;
    }
}

/**
 * apps.saleMode: NORMAL, HAPPY1, HAPPY2
 * CartItem.saleMode: NORMAL, HAPPY1, HAPPY2, SPECIAL
 */
export enum SaleMode {
    // happyhour 여부를 확인하기 위해서 사용하는 상태, apps.saleMode
    NORMAL= "NORMAL", HAPPY1 = "HAPPY1", HAPPY2 = "HAPPY2",

    // 개별 item에 적용된 saleMode로 tabletious에서 가격 계산할 때 사용
    SPECIAL = "SPECIAL",

    // 아직 shop 정보 가져오기 전에 세팅하는 초기 상태
    UNKNOWN = "UNKNOWN"
}

export class Happyhour {
    saleMode: SaleMode; // 0 | 1 | 2
    start: Date | null;
    end: Date | null;

    constructor(saleMode: SaleMode, start: Date, end: Date) {
        this.saleMode = saleMode;
        this.start = start;
        this.end = end;
    }
}

export default new Apps();
