import React, {useEffect, useState} from "react";
import {StyleSheet, Text, TouchableHighlight, TouchableWithoutFeedback} from "react-native";
import {Box, HStack, Image, VStack} from "native-base";
import {SwipeListView} from "react-native-swipe-list-view";
import {useRecoilState, useRecoilValue} from "recoil";
import {cartState} from "./state/cartState";
import {orderState} from "./state/orderState";
import {Cart, CartItem, mapToItems, OptionItemData, OptionListData} from "./lib/model";
import {useNavigation} from "@react-navigation/native";
import apps from "./lib/apps";

const Transparent: React.FC<{ onPress: () => void }> = ({onPress}) => {
    return (
        <TouchableWithoutFeedback onPress={onPress}>
            <Box style={styles.transparent}/>
        </TouchableWithoutFeedback>
    );
};

const CartTitle: React.FC<any> = () => {
    const cart = useRecoilValue(cartState);
    const yourOrder = useRecoilValue(orderState);

    console.log(cart);

    return (
        <Box style={styles.cartBack}>
            <HStack style={styles.cartLableBox}>
                <Text style={styles.cartLabel}>{cart.shop.name}</Text>
                <Text style={styles.cartTable}>{yourOrder.getOrderInfo()}</Text>
            </HStack>
        </Box>
    );
};

const CartItems: React.FC<{ onCartItemSelected: (selectedTag: string) => void }> = ({onCartItemSelected}) => {
    const [cart, setCart] = useRecoilState(cartState);

    const [items, setItems] = useState(mapToItems([]));

    useEffect(() => {
        setItems(mapToItems(cart.items));
    }, [cart]);

    const deleteItem = (menuTag: string) => {
        setCart(cart.removeItem(menuTag));
    };

    return (
        <Box style={{flex: 1}}>
            <SwipeListView
                data={items}
                renderItem={(data, rowMap) => (
                    <TouchableWithoutFeedback onPress={() => onCartItemSelected(data.item.key)}>
                        <HStack key={data.item.key} style={styles.itemList}>
                            <VStack style={styles.itemLeft}>
                                <Text style={styles.itemName}>{data.item.name}</Text>
                                <Text style={styles.itemOptions}>{data.item.optionsDescr()}</Text>
                            </VStack>
                            <VStack style={styles.itemRight}>
                                <Text style={styles.itemPrice}>${data.item.total}</Text>
                                <Box style={styles.itemQtyBox}>
                                    <Text style={styles.itemQty}>x{data.item.qty}</Text>
                                </Box>
                            </VStack>
                        </HStack>
                    </TouchableWithoutFeedback>
                )}
                renderHiddenItem={(data, rowMap) => (
                    <TouchableHighlight
                        style={styles.itemDeleteButton} onPress={() => {
                        deleteItem(data.item.key);
                    }}>
                        <Text style={styles.itemDeleteLabel}>REMOVE{"\n   "}ITEM</Text>
                    </TouchableHighlight>
                )}
                leftOpenValue={0}
                rightOpenValue={-75}
            />
        </Box>
    );
}

const CartButton: React.FC<{ onAdd: () => void }> = ({onAdd}) => {
    const cart = useRecoilValue(cartState);

    return (
        <VStack>
            <Box style={{height: 49}}>
                <HStack style={styles.subTotalPane}>
                    <Text style={styles.subTotalLabel}>Subtotal</Text>
                    <Text style={styles.subTotal}>${cart.total()}</Text>
                </HStack>
            </Box>
            <Box style={styles.addButtonPane}>
                <TouchableWithoutFeedback onPress={onAdd}>
                    <HStack style={styles.addButtonStack}>
                        <Text style={styles.addButtonLabel}>Go To Checkout</Text>
                        <Image source={require("../assets/bracket_HL.png")} alt="lb" h="22px" w="7px"
                               mt="1px" mr="3px"/>
                        <Text style={styles.addButtonPrice}>{/*${cart.total()}*/}${cart.total()}</Text>
                        <Image source={require("../assets/bracket_HR.png")} alt="lb" h="22px" w="7px"
                               mt="1px" ml="3px"/>
                    </HStack>
                </TouchableWithoutFeedback>
            </Box>
        </VStack>
    );
};

function onCartItemSelected(selectedTag: string, navigation) {
    navigation.replace("Menu", {selectedTag: selectedTag});
}

function CartView({navigation}) {
    const cart = useRecoilValue(cartState);

    const checkout = () => {
        navigation.replace("Checkout");
    };

    return (
        <VStack flex="1">
            <Transparent onPress={() => navigation.goBack()}/>
            <VStack style={styles.cart}>
                <CartTitle/>
                <CartItems onCartItemSelected={(selectedTag) => {onCartItemSelected(selectedTag, navigation)}}/>
                <CartButton onAdd={() => {
                    checkout();
                }}/>
            </VStack>
        </VStack>
    )
}

const styles = StyleSheet.create({
    transparent: {
        flex: 1, flexDirection: "column", justifyContent: "flex-end",
        backgroundColor: "#333", opacity: 0.7,
    }, cart: {
        width: "100%", backgroundColor: "#fff", justifyContent: "center",
        opacity: 1, flex: 1.5,
    }, cartBack: {
        backgroundColor: "#707070",
    }, cartLableBox: {
        width: "100%",
        height: 49,
        backgroundColor: "#fff",
        paddingTop: 18,
        justifyContent: "space-around",
        paddingHorizontal: 5,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        borderWidth: 1,
        borderColor: "#fff",
        borderBottomWidth: 1,
        borderBottomColor: "#dbd3c6",
    }, cartLabel: {
        fontSize: 16,
        fontWeight: "bold",
        letterSpacing: -0.4,
        color: "#434343",
    }, cartTable: {
        fontSize: 16,
        letterSpacing: -0.4,
        color: "#555",
    }, addButtonStack: {
        backgroundColor: "#db146d",
        borderRadius: 20,
        margin: 20,
        height: 58,
        marginLeft: 20,
        marginRight: 20,
        alignItems: "center",
        padding: 20,
    }, addButtonLabel: {
        flex: 1, fontSize: 18, fontWeight: "bold", color: "white",
    }, addButtonPrice: {
        fontSize: 18, fontWeight: "bold", color: "white",
    }, itemList: {
        width: "100%", backgroundColor: "white", height: 59,
        borderBottomWidth: 1, borderBottomColor: "#dbd3c6",
        paddingTop: 9,
    }, itemLeft: {
        marginLeft: 20, flex: 1, justifyContent: "center",
    }, itemName: {
        fontSize: 14,
        fontWeight: "bold",
        letterSpacing: -0.3,
        color: "#434343",
    }, itemOptions: {
        fontSize: 12,
        letterSpacing: -0.3,
        color: "#999",
    }, itemRight: {
        marginRight: 20, justifyContent: "flex-start", alignItems: "flex-end",
    }, itemPrice: {
        fontSize: 16,
        color: "#434343",
        letterSpacing: -0.3,
    }, itemQtyBox: {
        marginTop: 3,
        borderRadius: 9,
        borderWidth: 0,
        backgroundColor: "#999999",
        paddingHorizontal: 5,
        paddingVertical: 0,
        width: 25,
        height: 20,
        alignItems: "center",
        justifyContent: "center",
    }, itemQty: {
        marginTop: -2,
        fontSize: 12,
        fontWeight: "bold",
        color: "white",
    }, itemDeleteButton: {
        marginLeft: 200,
        backgroundColor: "#434343",
        alignItems: "flex-end", height: 59,
        justifyContent: "center",
        borderBottomWidth: 1, borderBottomColor: "#dbd3c6",
    }, itemDeleteLabel: {
        fontSize: 11,
        fontWeight: "bold",
        letterSpacing: -0.28,
        color: "white",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 12,
        lineHeight: 15,
    }, subTotalPane: {
        fontSize: 15, fontWeight: "bold", color: "#434343", letterSpacing: -0.3, flex: 1,
        backgroundColor: "#fff", justifyContent: "space-around", paddingHorizontal: 20,
        alignItems: "center",
    }, subTotalLabel: {
        fontSize: 15, fontWeight: "bold", color: "#434343", letterSpacing: -0.3, flex: 1,
    }, subTotal: {
        fontSize: 16, fontWeight: "bold", color: "#db146d", letterSpacing: -0.3,
    }, addButtonPane: {
        width: "100%",
        height: 100,
        borderTopWidth: 1,
        borderTopColor: "#e9e4dd",
    },
});

export default CartView;
